/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from '../../_components/Typography';


/**
 *
 *
 *
 */
export default function Forgot() {

  return (
    <S.Container>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  padding-top: 10px;
  height: 100%;
`;

S.PaneContainer = styled.div`
  width: 100%;
  height: 100%;
`;

S.HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

S.InnerContainer = styled.div`
  margin-top: 10px;
  height: calc(100% - 100px);
`;

S.MaybeDisplayNone = styled.div`
  height: 100%;
  ${p => p.$isDisplay ? `` : `
    display: none;
  `}
`;