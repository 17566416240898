/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import { darken, lighten, saturate } from 'polished';
import Spinner from '../../_components/Spinner';
import T from '../../_components/Typography';
import * as u from '../../utils';
import * as F from '../frgs';


/**
 *
 *
 *
 */
const isDev = process.env.NODE_ENV === 'development';
const next = isDev ? 'http://app.parvion.localhost:8045' : 'https://app.parvion.uk';


/**
 *
 *
 *
 */
export default function Home() {

  const nav = Router.useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState();

  return (
    <S.Container>
      <S.TopImage src="/assets/parvion.png" />
      <S.FormContainer onSubmit={onSubmit}>
        {isDev && (
          <React.Fragment>
            <S.Button $color={'#FD8C73'} onClick={onCreateFake}>
              <T.IL6 color="white">Fake Data</T.IL6>
            </S.Button>
            <div style={{ height: 15 }} />
          </React.Fragment>
        )}
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Email address</T.IL5>
          </S.SectionTopContainer>
          <S.Input required type="string" name="email" />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Password</T.IL5>
            <T.IM5 onClick={() => nav('/forgot')} color="#FD8C73">
              Forgot password?
            </T.IM5>
          </S.SectionTopContainer>
          <S.Input required type="password" name="password" />
        </S.SectionContainer>
        {error && (
          <S.SectionContainer>
            <T.IL5 color="red">{error}</T.IL5>
          </S.SectionContainer>
        )}
        <S.Button type="submit">
          {isLoading ? <Spinner /> : <T.IL6 color="white">Login</T.IL6>}
        </S.Button>
      </S.FormContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onSubmit(evt) {

    evt.preventDefault();

    try {
      setIsLoading(true);
      const [data, err] = await u.fetcher(F.AUTH_LOGIN, onParseForm(evt));
      if (err) throw Error('Something went wrong');
      if (data.email_password_mismatch) return setError('Wrong credentials');
      window.location.href = next;
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  }

  /**
   *
   *
   *
   */
  function onParseForm(evt) {
    const currForm = new FormData(evt.target);
    const data = {};
    for (const [k, v] of currForm.entries()) data[k] = v;
    return data;
  }

  /**
   *
   *
   *
   */
  function onCreateFake(evt) {
    evt.preventDefault();
    document.querySelector('input[name="email"]').value = 'admin@tamara.vet';
    document.querySelector('input[name="password"]').value = 'tbd639fga';
  }
};


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 400px;
  margin: 0px auto;
  padding-bottom: 50px;
  margin-top: 20px;
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.TopImage = styled.img`
  width: 400px;
  object-fit: cover;
  padding: 20px 15px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: 80px;
  margin-top: 40px;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

S.FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid rgb(216, 222, 228);
  background: rgb(246, 248, 250);
`;

S.BottomContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100px;
  width: 100%;
  margin-top: 25px;
  border-radius: 6px;
  border: 1px solid rgb(216, 222, 228);
  p:nth-child(3) {
    &:hover {
      cursor: pointer;
      color: ${lighten(0.1, saturate(0.1, '#FD8C73'))}
    }
  }
`;

S.SectionContainer = styled.div`
  margin-bottom: 20px;
`;

S.SectionTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  p:nth-child(2) {
    cursor: pointer;
    &:hover { color: ${lighten(0.1, saturate(0.1, '#FD8C73'))} }
  }
`;

S.Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 6px;
  padding-left: 6px;
  font-family: 'Inter';
  font-size: 14px;
`;

S.Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

S.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background: ${p => p.$color || 'rgba(9, 105, 218, 1)'};
  border: 1px solid ${p => p.$color || 'rgba(31, 35, 40, 0.15)'};
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color || 'rgba(9, 105, 218, 1)'))}; }
`;
