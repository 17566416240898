/**
 *
 *
 *
 */
export const AUTH_LOGIN = `
  mutation AuthLogin($email: String!, $password: String!) {
    auth_login(email: $email, password: $password)
  }
`;


/**
 *
 *
 *
 */
export const AUTH_FORGOT = `
  mutation AuthForgot($email: String!) {
    auth_forgot(email: $email)
  }
`;
